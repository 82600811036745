import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import Login from 'login/Login'
import GFWAPI from '@globalfishingwatch/api-client'
import useGFWLogin from '@globalfishingwatch/react-hooks/dist/use-login'
import Header from 'components/Header'
import Loader from 'components/Loader'

const Search = lazy(() => import('./search/Search'))
const Profile = lazy(() => import('./profile/Profile'))

GFWAPI.setConfig({ dataset: process.env.REACT_APP_DATASET || 'carriers:v20200820' })

const App = () => {
  const { loading, logged } = useGFWLogin(GFWAPI)

  if (loading) return <Loader />

  return logged ? (
    <Router>
      <Header />
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route exact path="/" component={Search} />
          <Route path="/profile/:id" component={Profile} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </Router>
  ) : (
    <Login />
  )
}

export default App
