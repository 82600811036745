import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { register } from './workers/serviceWorker.js'

ReactDOM.render(<App />, document.getElementById('root'))

// Register service worker, learn more: https://bit.ly/CRA-PWA
register()
