import React, { Component } from 'react'
import GFWAPI from '@globalfishingwatch/api-client'
import { ReactComponent as IconLogo } from '../assets/gfw_logo.svg'
import styles from './Login.module.css'

class Login extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <IconLogo className={styles.logoImage} />
          <h1 className={styles.textTitle}>Port Inspector App</h1>
          <h2 className={styles.textSubtitle}>
            Only registered users can use this tool. Please log in with your Global Fishing Watch
            credentials.
          </h2>
          <a className={styles.link} href={GFWAPI.getLoginUrl(window.location.toString())}>
            LOG IN
          </a>
        </div>
      </div>
    )
  }
}

export default Login
